<template>
  <NuxtLink
    v-if="hasLink && utils.isStoryLink(props.link)"
    :to="`/${linkUrl}`"
    :class="classes"
    :rel="rel"
  >
    <slot>
      {{ linkLabel }}
      <v-icon
        v-if="icon || (!props.button && !noIcon)"
        icon="mdi mdi-arrow-right"
      />
    </slot>
  </NuxtLink>
  <a
    v-else-if="hasLink && !utils.isEventLink(props.link)"
    :href="linkUrl"
    :class="classes"
    :rel="rel"
    :target="props.link.target"
  >
    <slot>
      {{ linkLabel }}
      <v-icon v-if="icon" icon="mdi mdi-arrow-right" />
    </slot>
  </a>
  <button
    v-else-if="hasLink && utils.isEventLink(props.link)"
    ref="button"
    :class="classes"
    @click.stop="
      () => {
        if (link.url === '@showSchedulePopup') {
          showSchedulePopup();
        } else if (link.url === '@showAnalyticsDemoPopup') {
          showAnalyticsDemoPopup();
        } else if (link.url === '@newsletterSubscribe') {
          emit('click');
        }
      }
    "
  >
    <slot>
      {{ linkLabel }}
      <v-icon v-if="!noIcon" icon="mdi mdi-arrow-right" />
    </slot>
  </button>
</template>

<script setup>
const button = ref(null);
const emit = defineEmits(["click"]);
button.value?.removeAttribute("href");

const props = defineProps({
  link: Object,
  linkText: String,
  button: Boolean,
  white: Boolean,
  noIcon: Boolean,
  icon: {
    type: Boolean,
    default: false,
  },
  rel: {
    type: String,
    default: "",
  },
});

const route = useRoute();

function isRouteActive(id) {
  if (route.path.includes(id)) {
    return true;
  } else {
    return false;
  }
}

const utils = useUtils();

function removeLastSlash(text) {
  // Finde die letzte Position des Zeichens "/"
  const lastIndex = text.lastIndexOf("/");

  // Wenn kein "/" gefunden wurde, gib den ursprünglichen Text zurück
  if (lastIndex === -1) {
    return text;
  }

  // Erzeuge einen neuen String ohne das erste "/" von rechts
  return text.slice(0, lastIndex) + text.slice(lastIndex + 1);
}

// Possible button events
const { showSchedulePopup, showAnalyticsDemoPopup } = useSchedule();
const hasLink = computed(() => linkUrl.value);
const linkUrl = computed(() => {
  return props.link ? utils.getUrlFromLink(props.link) : null;
});
const linkLabel = computed(() =>
  props.linkText
    ? props.linkText
    : props.link.title || props.link?.name || linkUrl.value,
);

const classes = computed(() => ({
  button: props.button,
  "grey-button": props.white,

  // "button-link w-inline-block": !utils.isStoryLink(props.link),
  "w--current": isRouteActive(removeLastSlash(linkUrl.value)),
}));
</script>

<style>
.no-link {
  cursor: default;
}
</style>
